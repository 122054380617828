import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
// import Review from "./Review";
import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { ImAttachment } from "react-icons/im";
import { useMediaQuery } from "react-responsive";
import { FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Reviews(props) {
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-width: 1224px)",
  // });
  // const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const titleOptions = [
    "Awesome",
    "Best in class",
    "Better than ever",
    "My Honest opinion",
    "Value for money",
    "Satisfied",
    "Worth every penny",
    "Great/Good quality",
    "Great deal",
    "Nice product",
    "Okay but not satisfied",
    "Love it",
    "Must buy",
    "Not as per expectations",
  ];
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  // const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const classes = useStyles();
  const initialData = {};
  const titleInitalData = {};
  for (let i = 0; i < props.reviewDetails.length; i++) {
    initialData[props.reviewDetails[i].productId] = props.reviewDetails[i];
    titleInitalData[props.reviewDetails[i].productId] =
      props.reviewDetails[i].title &&
      props.reviewDetails[i].title.length > 0 &&
      titleOptions.includes(props.reviewDetails[i].title);
  }

  console.log("HELLO", initialData);
  const [formData, setformData] = useState(initialData);
  const [titleData, settitleData] = useState(titleInitalData);
  const handleRating = (productId, newValue) => {
    const copyFormData = { ...formData };
    copyFormData[productId].rating = newValue;
    setformData(copyFormData);
  };
  const handleTitle = (productId, newValue) => {
    if (newValue === "other") {
      const copyTitleData = { ...titleData };
      copyTitleData[productId] = false;
      settitleData(copyTitleData);
      const copyFormData = { ...formData };
      copyFormData[productId].title = "";
      setformData(copyFormData);
      return;
    }
    const copyFormData = { ...formData };
    copyFormData[productId].title = newValue;
    if (titleOptions.includes(newValue)) {
      const copyTitleData = { ...titleData };
      copyTitleData[productId] = true;
      settitleData(copyTitleData);
    }
    setformData(copyFormData);
  };
  const handleBody = (productId, newValue) => {
    const copyFormData = { ...formData };
    copyFormData[productId].body = newValue;
    setformData(copyFormData);
  };
  const handleFiles = async (productId, files) => {
    const toast = cogoToast.loading("Uploading Files");

    const copyFormData = { ...formData };
    const filePromises = [];
    const attachments = [];
    for (let i = 0; i < files.length; i++) {
      const data = new FormData();
      data.append("file", files[i]);
      data.append("name", "placeholderForFileName");

      let customFileName = `${Date.now()}BSP${files[i].name}`;
      attachments.push(customFileName);
      filePromises.push(
        axios.post(
          `https://test.bitespeed.co/upload?shopUrl=${
            props.shopURL
          }&type=image&t=${customFileName.replace(/ /g, "")}`,
          data
        )
      );
    }

    const uploadedFiles = await Promise.all(filePromises);
    toast.hide();
    cogoToast.success("Images uploaded");
    if (
      !copyFormData[productId].attachments ||
      copyFormData[productId].attachments.length === 0
    ) {
      copyFormData[productId].attachments = "";
    }
    const attachmentsArray =
      copyFormData[productId].attachments.length > 0
        ? copyFormData[productId].attachments.split(",")
        : [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      let e = {
        target: {
          value: `https://cdn.bitespeed.co/uploads/${
            props.shopURL
          }/image/${attachments[i].replace(/ /g, "")}`,
        },
      };
      attachmentsArray.push(e.target.value);
    }
    copyFormData[productId].attachments = attachmentsArray.join(",");
    setformData(copyFormData);
  };
  const handleRemoveFiles = async (productId, fileName) => {
    const copyFormData = { ...formData };
    const attachemnts = copyFormData[productId].attachments.split(",");
    // remove attachments

    const newAttachments = attachemnts.filter((attachment) => {
      return !attachment.includes(fileName);
    });
    copyFormData[productId].attachments = newAttachments.join(",");
    setformData(copyFormData);
  };
  const handleSave = async () => {
    const toast = cogoToast.loading("Submitting Review");
    const copyFormData = { ...formData };
    //iterate over copyFormData object

    console.log("COPY FORM DATA", copyFormData);
    const options = {
      method: "POST",
      url: `https://test.bitespeed.co/database/api/updateReviewDetails`,
      data: {
        reviewDetails: Object.values(copyFormData),
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(options);
    toast.hide();
    cogoToast.success("Review Submitted");
  };
  console.log("FORM DATA", formData);
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {props.shopName}
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Paper
          className={classes.paper}
          style={{ width: "80vw", margin: "50px auto" }}
        >
          <Typography component="h1" variant="h4" align="center">
            PRODUCT REVIEWS
          </Typography>
          <br />
          <div style={{ padding: "0px 20px" }}>
            <Typography
              component="p"
              paragraph={true}
              align="left"
              style={{ marginBottom: "10px" }}
            >
              Hi {props.customerName},
            </Typography>
            <Typography
              component="p"
              paragraph={true}
              align="left"
              style={{ marginBottom: "10px" }}
            >
              You ordered a few products from us 14 days back and we'd like to
              know your feedback about them!
            </Typography>
            <Typography
              component="p"
              paragraph={true}
              align="left"
              style={{ marginBottom: "10px" }}
            >
              We’d love to know what you think! Please share your
              thoughts/comments/reviews with us
            </Typography>
            <Typography
              component="p"
              paragraph={true}
              align="left"
              style={{ marginBottom: "10px" }}
            >
              Love,
            </Typography>
            <Typography
              component="p"
              paragraph={true}
              align="left"
              style={{ marginBottom: "10px" }}
            >
              {props.shopName} Team
            </Typography>
          </div>
          <div style={{ padding: "20px" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              {props.products.map((item, idx) => {
                console.log(item.product, formData[item.product.id]);
                return (
                  <Grid
                    item
                    xs={isPortrait ? 12 : props.products.length >= 2 ? 6 : 6}
                    style={{
                      borderRight: isPortrait
                        ? "none"
                        : idx % 2 !== 0
                        ? "none"
                        : props.products.length >= 2
                        ? "2px solid #ccc"
                        : "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        alignItems: "flex-start",
                        borderBottom:
                          props.products.length > 1 ? "2px solid #ccc" : "none",
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          margin: "20px 0",
                        }}
                      >
                        <img
                          src={
                            item.product.image && item.product.image.src
                              ? item.product.image.src
                              : "https://cdn.bitespeed.co/assets/productImageTemplate.png"
                          }
                          style={{
                            maxWidth: "30%",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                          alt="product_image"
                        />
                        <div style={{ marginLeft: "20px" }}>
                          <Typography component="h5" variant="h6" align="left">
                            #{idx + 1}
                          </Typography>
                          <Typography
                            component="p"
                            variant="body1"
                            align="left"
                            style={{ fontWeight: "600" }}
                          >
                            {item.product.title}
                          </Typography>
                          <Typography component="p" variant="body" align="left">
                            YOUR RATING
                          </Typography>
                          <Rating
                            name="simple-controlled"
                            value={
                              formData[item.product.id].rating
                                ? formData[item.product.id].rating
                                : 0
                            }
                            onChange={(event, newValue) => {
                              handleRating(item.product.id, newValue);
                            }}
                          />
                        </div>
                      </div>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", backgroundColor: "#eaf2ff" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Review Title
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={
                            !titleData[item.product.id]
                              ? "other"
                              : formData[item.product.id].title
                          }
                          label="Review Title"
                          style={{ textAlign: "left" }}
                          onChange={(event) => {
                            handleTitle(item.product.id, event.target.value);
                          }}
                        >
                          <MenuItem value={"Awesome"}>Awesome</MenuItem>
                          <MenuItem value={"Best in class"}>
                            Best in class
                          </MenuItem>
                          <MenuItem value={"Better than ever"}>
                            Better than ever
                          </MenuItem>
                          <MenuItem value={"My Honest opinion"}>
                            My Honest opinion
                          </MenuItem>
                          <MenuItem value={"Value for money"}>
                            Value for money
                          </MenuItem>
                          <MenuItem value={"Satisfied"}>Satisfied</MenuItem>
                          <MenuItem value={"Worth every penny"}>
                            Worth every penny
                          </MenuItem>
                          <MenuItem value={"Great/Good quality"}>
                            Great/Good quality
                          </MenuItem>
                          <MenuItem value={"Great deal"}>Great deal</MenuItem>
                          <MenuItem value={"Nice product"}>
                            Nice product
                          </MenuItem>
                          <MenuItem value={"Okay but not satisfied"}>
                            Okay but not satisfied
                          </MenuItem>

                          <MenuItem value={"Love it"}>Love it</MenuItem>
                          <MenuItem value={"Not as per expectations"}>
                            Not as per expectations
                          </MenuItem>
                          <MenuItem value={"Must buy"}>Must buy</MenuItem>
                          <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                      </FormControl>
                      {!titleData[item.product.id] && (
                        <TextField
                          required
                          id="outlined-required"
                          label="Review Title"
                          defaultValue={formData[item.product.id].title}
                          variant="outlined"
                          style={{
                            width: "100%",
                            backgroundColor: "#EAF2ff",
                            marginTop: "20px",
                          }}
                          onChange={(e) => {
                            handleTitle(item.product.id, e.target.value);
                          }}
                          placeholder="Enter your review title"
                        />
                      )}

                      <TextareaAutosize
                        required
                        id="standard-required"
                        defaultValue={formData[item.product.id].body}
                        minRows={7}
                        placeholder="Enter your review"
                        style={{
                          width: "100%",
                          marginTop: "30px",
                          fontFamily: "",
                        }}
                        onChange={(e) => {
                          handleBody(item.product.id, e.target.value);
                        }}
                      />
                      <div>
                        {formData[item.product.id].attachments &&
                          formData[item.product.id].attachments.length > 0 &&
                          formData[item.product.id].attachments
                            .split(",")
                            .map((imageSrc, idx) => {
                              const fileName = imageSrc.includes("BSP")
                                ? imageSrc.split("BSP")[1]
                                : `UploadedFile ${idx + 1}`;
                              return (
                                <div
                                  style={{
                                    backgroundColor: "#eaf2ff",
                                    padding: "10px",
                                    margin: "10px 0",
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <ImAttachment />
                                  <a
                                    href={imageSrc}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontFamily: "inherit",
                                      fontWeight: "500",
                                      margin: "0 10px",
                                    }}
                                  >
                                    {fileName}
                                  </a>
                                  <GiCancel
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      handleRemoveFiles(
                                        item.product.id,
                                        imageSrc.includes("BSP")
                                          ? fileName
                                          : imageSrc
                                      );
                                    }}
                                  />
                                </div>
                              );
                            })}
                      </div>
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          backgroundColor: "#EAF2ff",
                          marginTop: "20px",
                          padding: "10px 25px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <span style={{ margin: "0 10px" }}>Upload Media</span>
                          <AiOutlineCloudUpload size={20} />
                        </div>
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={(e) =>
                            handleFiles(item.product.id, e.target.files)
                          }
                        />
                      </Button>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 20px",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                margin: "30px 10px 10px 10px",
                backgroundColor: "#3B56E6",
                color: "white",
              }}
              onClick={() => {
                window.open(`https://${props.shopURL}`, "popUpWindow");
              }}
            >
              Browse Store
            </Button>
            <Button
              variant="contained"
              style={{
                margin: "30px 0 10px 0",
                backgroundColor: "#3B56E6",
                color: "white",
              }}
              onClick={handleSave}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </main>
      {/* <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Order Confirmation
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for the confirmation.
                </Typography>
                <Typography variant="subtitle1">
                  {status == 1
                    ? "We have confirmed your order!"
                    : "We have update your order status to : CANCEL"}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, props.orderDetails, props.shopURL)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => {
                        handleNext(2);
                      }}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleNext(1);
                    }}
                    className={classes.button}
                  >
                    <b>
                      {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                    </b>
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        
      </main> */}
    </React.Fragment>
  );
}
