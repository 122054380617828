import "./App.css";

// import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Reviews from "./Reviews";
import React from "react";
import axios from "axios";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  componentDidMount = async () => {
    let url_str = window.location.href;
    let url = new URL(url_str);
    let orderId = url.searchParams.get("orderId");
    let shopURL = url.searchParams.get("shopUrl");
    console.log(
      `https://app.bitespeed.co/database/api/getReviewDetails/?shopUrl=${shopURL}&orderId=${orderId}`
    );
    let reviewDetails = await axios.get(
      `https://app.bitespeed.co/database/api/getReviewDetails/?shopUrl=${shopURL}&orderId=${orderId}`
    );

    // console.log("reviewDetails", reviewDetails.data);
    this.setState({
      orderId,
      shopURL,
      loaded: true,
      reviewDetails: reviewDetails.data,
    });
    document.title = `${this.state.reviewDetails.shopName} | Product Reviews`;
  };
  render() {
    return (
      <div className="App">
        {!this.state.loaded ? (
          <div className="loaderContainer">
            <CircularProgress />
          </div>
        ) : (
          <Reviews
            customerName={this.state.reviewDetails.customerName}
            shopName={this.state.reviewDetails.shopName}
            reviewDetails={this.state.reviewDetails.reviewDetails}
            products={this.state.reviewDetails.products}
            shopURL={this.state.shopURL}
          />
        )}
      </div>
    );
  }
}

export default App;
